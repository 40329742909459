@import './variables.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
}


.main-header {
  position: fixed;
  width: 100%;
  height: 60px;
  text-align: left;
  background-color: $oneyellow;
  border-bottom: 2px solid $lightgrey;
  z-index: 10;

  .menu-mobile {
    display: none;
  }

  .logo {
    margin-top: 10px; 
    margin-left: 20px;
  }



  .harmony-price {
    position: absolute;
    left: 250px;
    top: 20px;

    .logo-harmony {
      width: 20px;
      margin-bottom: -3px;
    }

    .price {
      margin-left: 10px;
      font-size: 20px;
    }

    .rank {
      margin-left: 5px;;
    }
  }

  .stake-link {
    position: absolute;
    left: 450px;
    top: 24px;
    
    font-weight: bold;
    text-decoration: underline;

    a {
      color: $oneblue; 
    }
  }

  .langages {
    position: absolute;
    right: 20px;
    top: 15px;

    .button-one {
      margin-left:  10px;
      padding: 8px 5px 5px 5px;
      border: 1px solid transparent;

      &.active {
        border: 1px solid $oneblue;
        border-radius: 25px;
      }

      &:first {
        margin-left: 0;
      }
    }
  }
}

.navigation {
  position: fixed; 
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  width: 240px;
  text-align: left;
  // padding-top: 60px;
  top: 0px;
  left: 0px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  flex-shrink: 0;
  height: 100vh;
  z-index: 1;
  overflow: initial;
  transform: translate3d(0, 0, 0);

  .nav-links {
    // padding-top: 50px;
    .nav-link {
      padding: 20px 20px;
      a {
        color: #000;
        font-size: 20px;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}


.main-container {
  background-color: $bggrey;
  width: calc(100% - 240px);
  margin-left: 240px;
  min-height: calc(100vh - 60px);
  padding-top: 60px;

  .page {
    padding: 40px;
    min-height: calc(100vh - 60px); 
  }
}



@media only screen and (max-width: $small-width) {


  .main-header {
    .menu-mobile {
      display: block;
      left: 20px;
      top: 21px;
      position: absolute;
      width: 20px;
      height: 18px;
      border-bottom: 3px solid #000;

      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        height: 10px;
        border-top: 3px solid #000;
        border-bottom: 3px solid #000;
      }
    }

    .logo {
      float: right;
      margin-right: 10px;
    }

    .harmony-price {
      left: 60px;

      .rank {
        display: none;
      }

      .price {
        margin-left: 6px;
      }
    }


    .stake-link {
      left: 170px;

      .stake-link-one {
        display: none;
      }
    }

    .langages {
      right: 60px;

      .button-one {
        margin-left:  5px;
      }
    }

  }

  .navigation {
    background-color: white;
  }
  
  .main-container {
    margin-left: 0;
    width: 100%;

    .page {
      padding: 20px;
    }

    .menu-veil {
      background-color: #000;
      opacity: .5;
      width: 100vw;
      position: fixed;
      height: 100vh;
      top: 0;
    }
  }
}


@media (prefers-reduced-motion: no-preference) {
  /* .App-logo { */
    /* animation: App-logo-spin infinite 20s linear; */
  /* } */
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
