@import './../variables.scss';

.home {
  // margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  .harmony-container {
    // position: absolute;
    // top: 100px;
    margin-bottom: 50px;
    .logo-harmony {
      margin-left: 10px;
      width: 50px;
    } 
  }

  .logo-one4all {
    width: 200px;
  }

  .powered {
    vertical-align: top;
    margin-top: 17px;
    font-weight: bold;
    display: inline-block;
  }

  .title {
    color: $oneyellow;
    font-size: 50px; 
    font-weight: bold;
    line-height: 45px;
    margin: 10px 0 0 0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $oneblue;
  }
  
  .subtitle {
    margin: 0;
    font-style: italic;
    line-height: 20px;
    margin-bottom: 10px;;
  }

  .fees {
    font-weight: bold;
  }

  .staking-help {
    margin: 10px 0;

  }

  .informations {
    margin: 10px 0;
    font-size: 20px;
    line-height: 30px;


    .stats-container {
      margin: 20px 0;
      .stats {
        width: 300px;
        margin: auto;
        background-color: $oneyellow;
        padding: 10px 0;
        border-radius: 20px;
        border: 2px solid $oneblue;

        .stat {
          // margin-right: 10px;
          
          &.active,
          &.elected {
            text-transform: capitalize;
          }

          &.is-active,
          &.is-elected {
            color: green;
          }


          &.stake, 
          &.delegators {
            font-size: 40px;
            line-height: 40px;
            margin-top: 10px;
            font-weight: bold;
            color: $oneblue; 
          }

          &.stake-label,
          &.delegators-label {
            font-size: 12px;
            line-height: 20px
          }
        }
      }

      .loading {
        img {
          height: 157px;
        }
      }
    }

    a {
      color: $oneblue;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .description {
    margin-top: 30px;
    text-align: left;
    line-height: 24px;
  }
  
  .twitter-timeline {
    margin-top: 30px;
    min-height: 400px;
  }
}

@media only screen and (max-width: $small-width) {
  .home {
    .harmony-container {
      position: relative;
      top: auto;
      margin-top: 10px;
    }

    .informations {
      font-size: 15px;
    }

  }

}