@import './../variables.scss';

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .arobase {
    width: 20px;
    margin-bottom: -5px;
  }

  .logo-twitter {
    margin-left: 0;
  }

  ul {
    text-align: left;
    li {
        margin: 10px 0;

        strong { 
           display: inline-block;
            color: $oneblue;
            font-size: 18px; 
            min-width: 100px;
            font-weight: bold;
        }
    }
}
}