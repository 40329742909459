@import './../variables.scss';

.ecosystem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  .ecosystem-cat {
    margin-bottom: 20px;

    .social-links-icons {
      white-space: nowrap;
      display: inline-block;
      min-width: 122px;
    }
  }



  ul {
      li {
          margin: 10px 0;
          line-height: 40px;
          // height: 40px;
          padding-left: 50px;
          position: relative;

          .logo-eco {
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            margin-right: 10px;
            margin-bottom: -12px;
          }

          .logo-social {
            margin-right: 8px;
          }

          .logo-twitter {
            margin-bottom: -3px;
          }

          .logo-telegram {
            margin-bottom: -4px;
          }

          .logo-github {
            margin-bottom: -4px;
          }
  
          .logo-discord {
            margin-bottom:  -5px;
          }

          .logo-stats {
            margin-bottom: -3px;
          }

          strong { 
              font-weight: bold;
              color: $oneblue;
              font-size: 18px; 
          }

          .eco-name {
            display: inline-block;
            min-width: 130px;
            font-weight: bold;
            color: $oneblue;
            font-size: 18px; 
          }

          .price,
          .tvl {
            font-size: 16px;
            color: $oneblue;
            margin-right: 5px;
          }

          .tvl {
            white-space: nowrap;
            
            .tvl-label {
              font-weight: bold;
            }
          }
      }
  }

}


@media only screen and (max-width: $small-width) {
  .ecosystem {
    ul li {
      line-height: 30px;
    }
  }
}