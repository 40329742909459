@import './../../variables.scss';

.openswap {

    h2,
    h3,
    h4 {
        margin-top: 20px;
        margin-bottom: 20px;
        color: $oneblue;
        font-weight: bold;
    }

    h2 {
        font-size: 30px;
        line-height: 40px;
    }


    h3 {
        font-size: 24px;
        line-height: 30px;
    }

    h4 {
        font-size: 20px;
        line-height: 24px;
 
        &::before {
            content: '';
            height: 10px;
            width: 10px;
            background-color: $oneyellow;
            border: 1px solid $oneblue;
            border-radius: 50%;
            display: inline-block;
            margin-left: 5px;
            margin-right: 10px;
        }
    }

    p {
        font-size: 16px;
        line-height: 24px;
        margin: 10px 0;
    }

    .table-container {
        width: calc(100vw - 40px);
        overflow: auto;

        table {
            margin: 20px 0;
            tr {
                border-bottom: 1px solid $lightgrey;
            }
            th {
                color: $grey;
                font-weight: bold;
                font-size: 20px;
                padding: 10px;
            }

            td {
                padding: 10px;
                
                &.label {
                    font-weight: bold;
                }
                p {
                    margin: 5px 0;
                }
            }
        }
    }

    .info {
        min-height: 35px;
        margin: 20px 0;
        padding: 10px 10px 10px 50px;
        background: url("./../../images/info.png") no-repeat;
        background-position-x: 10px;
        background-position-y: 5px;
        border-left: 2px solid $oneblue;
        background-color: rgb(235, 235, 235);
    }

    figure {
        text-align: center;
        margin: 30px 0; 
        img {
            width: 600px;
            margin: auto;

            &.wider {
                width: 500px;
            }
        }

        figcaption {
            margin-top: 5px;
            font-style: italic;
            color: $grey;
        }
    }
  
}


@media only screen and (max-width: $small-width) {
    .openswap {
        figure {
            img {
                width: calc(100vw - 40px);
    
                &.wider {
                    width: calc(100vw - 40px);
                }
            }
        }
    }
}