@import './../variables.scss';

.justice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .logo {
    margin-bottom: -15px;
  }
  
  .title-section {
    margin-top: 30px;
  }

  p {
    line-height: 30px;
  }

  ul {
    li {
        margin: 10px 0;
        line-height: 30px;

        .hero-logo {
          display: inline-block;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          border: 2px solid $oneblue;
          margin-bottom: -12px;
          margin-right: 5px;
          background-size: 100%;
          background-position: center;
          background-repeat: no-repeat;

          &.logo-90 {
            background-size: 90%;
          }
        }


        .hero-infos {
          display: inline-block;
          line-height: 50px;
          margin-left: 5px;
          vertical-align: top;

          .validator {
            color: $oneblue;
            text-decoration: underline;
            margin-left: 10px;
          }

          .logo-website {
            margin-left: 10px;
            margin-bottom: -4px;
          }

          
          .logo-github {
            margin-left: 10px;
            margin-bottom: -4px;
          }
          
        }

        .logo-twitter {
          margin-bottom: -3px;
        }

        .logo-ph {
          width: 40px;
          margin-bottom: -12px;
          margin-right: 5px;;
        }

        strong { 
            color: $oneblue;
            font-size: 18px; 
            font-weight: bold;
        }
    }
  }
}