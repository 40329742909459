@import './../variables.scss';
@import './../global.scss';

.why {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;


    .title-section {
        margin-top: 30px;
    }

    ul {
        li {
            margin: 10px 0;
            line-height: 20px;
            strong { 
                color: $oneblue;
                font-weight: bold;
                font-size: 18px; 
            }
        }
    }


    .gallery { 
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .gallery-container {
            width: 100%;
        }

        .gallery-thumbnail {
            width: 100%;
            margin-top: 10px;
            cursor: pointer;
            vertical-align: top;

            &.gallery-thumbnail-50 {
                width: calc(50% - 10px);
                &:nth-child(2n+1) {
                    margin-right: 10px;
                }
                &:nth-child(2n) {
                    margin-left: 10px;
                }
            }
        } 

        img {
            object-fit: contain;
        }
    }
}

@media only screen and (max-width: $small-width) {
    .why {
        .gallery {
            .gallery-thumbnail {
                width: 100%;

                &.gallery-thumbnail-50 {
                    width: 100%;
                    &:nth-child(2n+1) {
                        margin-right: 0;
                    }
                    &:nth-child(2n) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
  
  }