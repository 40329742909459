@import './../variables.scss';

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  ul {
      li {
          margin: 10px 0;
          line-height: 30px;

          .logo-eco {
            width: 40px;
            margin-right: 10px;
            margin-bottom: -12px;
          }

          .logo-twitter {
            margin-bottom: -3px;
          }

          strong { 
              font-weight: bold;
              color: $oneblue;
              font-size: 18px; 
          }
      }
  }

}