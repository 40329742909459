@import './variables.scss';

.none {
    display: none !important;
}

.logo-twitter {
    width: 20px;
    margin-left: 10px;
}

.logo-telegram {
    width: 20px;
}

.logo-website {
   width: 20px;
}

.logo-github {
    width: 20px;
 }

 .logo-discord {
    width: 20px;
 }

 .logo-stats {
    width: 18px;
 }

.main-title {
    color: $oneyellow;
    font-size: 50px; 
    line-height: 45px;
    margin: 10px 0 0 0;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $oneblue;
    margin-bottom: 50px;
    text-align: center;
}

.title-section {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    line-height: 30px;

    color: $oneyellow;
    font-size: 24px; 
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $oneblue;
}

.button-reset {
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background-color: transparent;
	cursor: pointer; 
}

.button-one4all {
    border: 1px solid $oneblue;
    border-radius: 10px;
    padding: 10px;
    font-size: 18px;
    color: $oneblue;
    font-weight: bold;

    &:hover, 
    &.active {
        background-color: $oneyellow;
    }
}

.flag {
    width: 20px;
    height: 15px;
    object-fit: cover;
}


.date {
    font-style: italic;
}

.italic {
    font-style: italic; 
}