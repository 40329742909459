@import './../variables.scss';
@import './../global.scss';

.news {
    display: flex;
    flex-direction: column;
    text-align: left;


    .title-section {
        margin-top: 30px;
    }

    .noResults {
        font-style: italic;
        margin: 20px 0;
    }

    ul {
        li {
            margin: 10px 0;
            line-height: 20px;
            strong { 
                color: $oneblue;
                font-weight: bold;
                font-size: 18px; 
            }
        }
    }

    .search-section {
        margin: 20px auto;
        width: 800px;
    }

    .news-week {
        // padding: 20px;
        border: 1px solid $oneblue;
        border-radius: 10px;
        margin: 20px auto;
        width: 800px;
        overflow: hidden;

        .news-header {
            padding: 10px 20px;
            background-color: $oneblue;
            color: $oneyellow;
            display: flex;
            justify-content: space-between;
            

            .news-yearweek {
                margin-left: 15px;
                font-size: 18px;
                line-height: 40px;
            }

            .news-date-range {
                display: flex;
                font-size: 22px;
                line-height: 36px;
                font-weight: bold;
                margin-left: 10px;
                .start-week {
                    margin-right: 5px; 
                }
                .end-week {
                    margin-left: 5px;
                }

            }

            .news-logos {
                display: flex;

                .logo-harmony {
                    width: 30px;
                    margin-top: 5px;
                    margin-right: 10px;
                }
                .logo{
                    width: 40px;
                    height: 40px;
                }

                .logo-hound {
                    margin-top: 2px;
                    width: 36px;
                    height: 36px;
                }

                .logo-separator {
                    font-size: 16px;
                    margin-top: 10px;
                    margin-left: 5px;
                    margin-right: 3px;
                }
            }
        }
        .news-day {
            display: flex;
            padding: 15px 20px;
            border-bottom: 1px dashed $oneblue;
            line-height: 20px;

            &:last-child {
                border-bottom: 0;
            }

            .news-day-container {
                padding: 0 10px;
                color: $oneblue;
                font-style: italic;
                min-width: 150px;
            }
            .news-details-container {
                margin-bottom: 10px;
                padding-bottom: 15px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    width: 50px;
                    left: calc((100% - 25px) / 2);
                    bottom: 0;
                    height: 1px;
                    background-color: $oneblue;
                    display: inline-block;
                }


                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    &:before {
                        display: none;
                    }
                }

                .date {
                    
                }

                .titleDesc {
                    margin-bottom: 2px;
                    .title {
                        font-weight: bold;
                    }
                    .description {
                        
                    }
                }
               
                .reference {
                    font-size: 12px;
                    line-height: 14px;
                    font-style: italic;
                    margin: 2px 0;
                    a {
                        color: $oneblue;
                        text-decoration: underline;
                    }
                }
                .url {
                    font-size: 12px;
                    line-height: 14px;
                    margin: 2px 0;
                    a {
                        color: $oneblue;
                        text-decoration: underline;
                    }
                }


            }
        }
    }

    
}

@media only screen and (max-width: $small-width) {
    .news {
        .search-section {
            width: 100%;
        }

        .news-week {
            width: 100%;

            .news-header {
                padding: 10px;
                .news-date-range {
                    font-size: 20px;
                    line-height: 20px;
                }

                .news-yearweek {
                    line-height: 20px;
                }

                .news-logos {
                    .logo {
                        margin-left: 5px;
                    }

                    .logo-separator {
                        display: none;
                    }
                }
            }
            
          

            .news-day {
                flex-direction: column;

                .news-day-container {
                    padding: 0;
                    margin-bottom: 10px;
                }
            }
        }  
    }
  
  }