@import './../../variables.scss';

.langage-selector {

  &.show {
    .btn-secondary {
      background-color: transparent;
      border: 0;
    }
  }

  .dropdown-menu {
    width: auto;
    min-width: auto;

    button {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  } 

  .dropdown-toggle {
    &::after {
      color: $black;
    }
  }

  .btn-secondary {
    background-color: transparent;
    border: 0;
  }
}