@import './../variables.scss';

.guide {
  display: flex;
  flex-direction: column;
  text-align: left;

  .guides {
    display: flex;
    flex-direction: row;
    .left-container {
      min-width: 280px;
      position: relative;


      .guides-move {
        position: fixed;
      }
    }

    .right-container {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  .guide-container {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
  }

  .button-one4all {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  ul {
      li {
          margin: 10px 0;
          line-height: 30px;

          .logo-eco {
            width: 40px;
            margin-right: 10px;
            margin-bottom: -12px;
          }

          .logo-twitter {
            margin-bottom: -3px;
          }

          strong { 
              font-weight: bold;
              color: $oneblue;
              font-size: 18px; 
          }
      }
  }

}


@media only screen and (max-width: $medium-width) {
  .guide {
    .guides {
      display: flex;
      flex-direction: column;
      .left-container {
        min-width: auto;

        .guides-move {
          position: relative;
        }
      }

      .right-container {
        padding: 0;
      }
  
    
    }
  }
}
